const zIndex = {
    "-100": -100, 
    "-1": -1, 
    "1": 1, 
    "10": 10, 
    "20": 20, 
    "30": 30, 
    "100": 100, 
    "200": 200, 
    "1000": 1000, 
    "10000": 10000, 
}

export default zIndex